<template>
  <VeeForm
    @submit="submitForm"
    v-slot="{ errors }"
    class="form tiny-input edit-form"
    novalidate
  >
    <mobile-screen
      :header="true"
      screen-class="gray-bg icon-app1 app-version"
      v-if="selectedApplication && selectedApplication.id"
    >
      <template v-slot:header>
        <top-header-menu-wrapper menu-class="app-versions-header icon-hea1">
          <template v-slot:left>
            <router-link
              :to="{ name: 'r_application-versions' }"
              v-if="!editable"
            >
              <icon icon="#cx-hea1-arrow-left" />
            </router-link>
            <button @click="editable = false" v-if="editable">
              <icon icon="#cx-hea1-edit-cancel" />
            </button>
          </template>
          <div class="component-title">
            {{ selectedApplicationCopy.name }}
          </div>
          <template v-slot:right v-if="!subdomain">
            <button @click="editable = true" v-if="!editable">
              <icon icon="#cx-hea1-edit" />
            </button>
            <button v-if="editable">
              <icon icon="#cx-hea1-edit-done" />
            </button>
          </template>
        </top-header-menu-wrapper>
      </template>
      <ul class="clebex-item-section">
        <li class="clebex-item-section-item full-right-underline">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="appName"
                >{{ displayLabelName("about", "app-version", "title") }}
              </label>
              <Field
                name="name"
                as="input"
                rules="required"
                type="text"
                id="appName"
                :disabled="!editable"
                v-model="selectedApplicationCopy.name"
              />
              <span class="error-message" v-if="errors.name">
                {{ errors.name }}
              </span>
            </div>
          </div>
        </li>
        <li class="clebex-item-section-item full-right-underline">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="versionNumber"
                >{{ displayLabelName("about", "app-version", "version") }}
              </label>
              <Field
                name="number"
                as="input"
                rules="required"
                type="text"
                :disabled="!editable"
                v-model="selectedVersionCopy.number"
              />
              <span class="error-message" v-if="errors.number">
                {{ errors.number }}
              </span>
            </div>
          </div>
        </li>
        <li class="clebex-item-section-item full-right-underline">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="versionDate"
                >{{ displayLabelName("about", "app-version", "date") }}
              </label>
              <Field
                v-if="editable"
                name="date"
                as="input"
                rules="required"
                type="text"
                id="versionDate"
                :disabled="!editable"
                v-model="selectedVersionCopy.date"
              />
              <span class="error-message" v-if="editable && errors.date">
                {{ errors.date }}
              </span>
              <p v-if="!editable" class="text-label">
                {{ selectedVersionCopy.date }}
              </p>
            </div>
          </div>
        </li>
      </ul>
      <ul class="clebex-item-section">
        <li class="clebex-item-section-item full-right-underline">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="versionDescription"
                >{{ displayLabelName("about", "app-version", "release-notes") }}
              </label>
              <Field
                name="description"
                as="textarea"
                rules="required"
                rows="4"
                cols="50"
                type="text"
                id="versionDescription"
                :disabled="!editable"
                v-model="selectedVersionCopy.description"
              />
              <span class="error-message" v-if="errors.description">
                {{ errors.description }}
              </span>
            </div>
          </div>
        </li>
      </ul>
    </mobile-screen>
  </VeeForm>
  <router-view />
</template>

<script>
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import MobileScreen from "@/layouts/MobileScreen";
import { mapActions, mapState, mapGetters } from "vuex";
import httpServiceAuth, { getSubdomain } from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { formatDate } from "@/services/helpers";

dayjs.extend(customParseFormat);

export default {
  name: "DisplayVersion",
  data() {
    return {
      subdomain: getSubdomain(),
      selectedVersionCopy: null,
      selectedAppVersionsCopy: null,
      selectedApplicationCopy: null,
      editable: false,
      version: {
        application_id: "",
        number: "",
        description: "",
        is_latest: true,
        date: dayjs().format("YYYY-MM-DD")
      },
      applicationsCopy: null
    };
  },
  created() {
    if (this.selectedApplication === null || this.selectedVersion === null) {
      this.$router.push({ name: "r_about" });
    }
    this.selectedVersionCopy = { ...this.selectedVersion };
    this.selectedApplicationCopy = { ...this.selectedApplication };
    this.selectedAppVersionsCopy = { ...this.selectedAppVersions };
  },
  computed: {
    ...mapState("applications", [
      "applications",
      "selectedApplication",
      "selectedVersion",
      "selectedAppVersions"
    ]),
    ...mapGetters("settings", ["globalDateFormat"])
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  methods: {
    ...mapActions("applications", [
      "setSelectedVersion",
      "setSelectedApplication",
      "setSelectedAppVersions",
      "getApplications",
      "getAppVersions"
    ]),
    submitForm(values, { resetForm }) {
      if (values) {
        this.editable = false;
        this.submitMasterData(values, resetForm);
      }
    },
    submitMasterData(values, resetForm) {
      values.date = values.date + "T00:00:00+02:00";

      this.$store.commit("loader/setScreenLoading", true, { root: true });
      if (this.selectedApplicationCopy.name === this.selectedApplication.name) {
        this.setVersions(resetForm);
      } else {
        httpServiceAuth
          .put(
            `${apiEndpoints.master.applications}/${this.selectedApplicationCopy.id}`,
            { name: values.name }
          )
          .then(() => {
            this.setSelectedApplication(this.selectedApplicationCopy);
            this.setVersions(resetForm);
          })
          .catch(error => {
            if (error.response) {
              errorHandler(error.response, this.findElement());
            }
          })
          .finally(() => {
            this.$store.commit("loader/setScreenLoading", false, {
              root: true
            });
          });
      }
    },
    setVersions(resetForm) {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      const promises = [];
      const promise = new Promise((resolve, reject) => {
        // this.selectedVersionCopy.date = dayjs(
        //   this.selectedVersionCopy.date,
        //   "DD.MM.YYYY"
        // ).format("YYYY-MM-DD");
        httpServiceAuth
          .put(
            `${apiEndpoints.master.versions}/${this.selectedVersionCopy.id}`,
            this.selectedVersionCopy
          )
          .then(() => {
            resolve(true);
            this.$router.push({ name: "r_application-versions" });
            this.$store.commit("loader/setScreenLoading", false, {
              root: true
            });
          })
          .catch(err => {
            reject(false);
            if (err.response) {
              errorHandler(err.response, this.findElement());
            }
          });
      });
      promises.push(promise);
      Promise.all(promises).then(() => {
        this.getAppVersions(this.selectedApplicationCopy.id);
        this.getApplications();
        resetForm();
        this.$store.commit("loader/setScreenLoading", false, {
          root: true
        });
        this.$router.push({ name: "r_application-versions" });
      });
    },
    displayDate(date) {
      if (!date) {
        return "";
        // } else if (!getSubdomain()) {
        //   return date;
        // }
      }
      return formatDate(date, this.globalDateFormat);
    }
  },
  watch: {
    selectedVersion() {
      this.selectedVersionCopy = { ...this.selectedVersion };
    }
  }
};
</script>
